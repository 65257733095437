import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text
} from '@chakra-ui/react';

const ModalDelete = ({ isOpen, onClose, onConfirm }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirmação de Exclusão</ModalHeader>
                <ModalBody>
                    <Text>Você tem certeza que deseja excluir este item?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={onConfirm}>Confirmar</Button>
                    <Button variant="ghost" onClick={onClose}>Cancelar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalDelete;
